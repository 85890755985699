import { socket } from 'core/socket/socketClient'
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react'
import { SOCKET_EVENTS } from '../constants/pinpadSocketEvents'

export const panicFnNotImplemented = (fnName) => () => {
  throw new Error(`${fnName} :: NOT IMPLEMENTED`)
}

const PinpadSocketContext = createContext({
  isConnected: false,
  connect: () => {},
  disconnect: () => {},

  emitPaymentRequest: panicFnNotImplemented('emitPaymentRequest'),
  setChargeAlertResponseCallbacks: panicFnNotImplemented('setChargeAlertResponseCallbacks'),

  mounted: false
})

export const PinpadSocketProvider = ({ children }) => {
  const [isConnected, setIsConnected] = useState(false)
  const [hasPaymentError, setHasPaymentError] = useState(false)
  const [pinpadNotFound, setPinpadNotFound] = useState(false)
  const [_pingSuccess, setPingSuccess] = useState(socket.connected)
  const [_connectedAs, setConectedAs] = useState(undefined)

  const [chargeAlertResponseCallbacks, setChargeAlertResponseCallbacks] = useState({})

  const listenForEventList = useCallback((events) => {
    Object.entries(events).forEach((event) => socket.on(...event))
  }, [])

  const dropEventList = useCallback((events) => {
    Object.entries(events).forEach((event) => socket.off(...event))
  }, [])

  const connect = useCallback(() => {
    if (!socket.connected) { socket.connect() }
  }, [])

  const disconnect = useCallback(() => {
    if (socket) { socket.close() }
  }, [])

  useEffect(() => {
    const connectedEvents = {
      ...chargeAlertResponseCallbacks,
      [SOCKET_EVENTS.onPing]: (data) => {
        setPingSuccess(true)
        setConectedAs(data.as)
      },
      alert: (data) => {
        if (data?.type === 'error') {
          if (data?.code === 'pinpad_not_found') {
            setPinpadNotFound(true)
          } else {
            setHasPaymentError(true)
          }
        }
      }
    }

    if (isConnected) {
      listenForEventList(connectedEvents)
    } else {
      dropEventList(connectedEvents)
    }
    return () => dropEventList(connectedEvents)
  }, [
    chargeAlertResponseCallbacks,
    dropEventList,
    isConnected,
    listenForEventList
  ])

  useEffect(() => {
    function onConnect () {
      setIsConnected(true)
    }

    function onDisconnect () {
      setIsConnected(false)
    }

    socket.on('connect', onConnect)
    socket.on(SOCKET_EVENTS.onDisconnect, onDisconnect)

    return () => {
      socket.off('connect', onConnect)
      socket.off(SOCKET_EVENTS.onDisconnect, onDisconnect)
    }
  }, [])

  useEffect(() => {
    const updateConnection = () => {
      if (socket.connected !== isConnected) {
        setIsConnected(socket.connected)
      }
    }
    const interval = setInterval(updateConnection, 2500)
    return () => clearInterval(interval)
  }, [isConnected])

  const emitPaymentRequest = useCallback((
    paymentRequest,
    pinpadId
  ) => {
    const safePaymentRequest = {
      ...paymentRequest,
      pinpadId,
      paymentMethod: paymentRequest.paymentMethod === 'pay_pix' ? 'pix' : paymentRequest.paymentMethod,
      installments: paymentRequest.installments || 1
    }

    setPinpadNotFound(false)
    setHasPaymentError(false)
    socket
      .timeout(5_000)
      .emit(
        SOCKET_EVENTS.emitRequestAnOrbPayment,
        safePaymentRequest
      )
  }, [])

  return (
    <PinpadSocketContext.Provider value={{
      isConnected,
      connect,
      disconnect,
      mounted: true,
      emitPaymentRequest,
      setChargeAlertResponseCallbacks,
      hasPaymentError,
      pinpadNotFound
    }}>
      {children}
    </PinpadSocketContext.Provider>
  )
}

export const usePinpadSocket = () => {
  const context = useContext(PinpadSocketContext)

  if (!context.mounted) {
    throw new Error('usePinpadSocket must be used within PinpadSocketProvider')
  }

  return context
}
