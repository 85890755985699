/* eslint-disable camelcase */
/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { TableContainer, TableHeader, TableHeaderRow, TableHeaderCell, TableBody, TableBodyRow, CircleButton, Checkbox, ConfirmDialog, SortRightIcon, H3, EllipsisIcon, Dialog, Label, GhostPrimaryButton } from '@bonitour/components'
import { identity, tail } from '@bonitour/common-functions'
import { editableTicketStatus } from 'constants/ticketStatus'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { TicketRow } from './TicketRow'
import { useCreateVoucher } from './hooks/useCreateVoucher'
import { alignCenter, tableContainer, dialogButtons, select, ticketsContainer, sortIcon, customThTransport, optionsIcon, optionsContainer, optionsContainerButton, dialogContainer, dialogFullSize } from './Tickets.style'
import { useCancelVoucher } from './hooks/useCancelVoucher'
import { arrowOpenIndicator } from 'assets/styles/global'
import { useCancelTicketsBatch } from './hooks/useCancelTicketsBatch'
import { useCompany } from 'contexts/Company'
import { EditDeadlineNoShow } from './EditDeadlineNoShow'
import { fiscalDutyDialog } from 'domains/Reservation/Show/Tickets/TicketSummary'
import { OFFLINE_EXPERIENCE_TYPE, TRANSPORT_TYPE } from 'constants/activityTypes'

export const Tickets = ({
  activityType = '',
  groupedByService = true,
  tickets = [],
  reservation = [],
  pickupTickets = [],
  serviceId = '',
  reservationId = '',
  companyId = '',
  deleteTicketLoading = false,
  ticketConfirmLoading = false,
  pickupLocalLoading = false,
  observationLoading = false,
  linkPassengerLoading,
  onTicketEdit: emitEditEvent = identity,
  onTicketDelete = identity,
  onTicketPrint = identity,
  onTicketPrintVoucherTuris = identity,
  onTicketConfirm = identity,
  onLinkPassenger = identity,
  onUnlinkPassenger = identity,
  onEditPassenger = identity,
  onLinkPickup = identity,
  onUnlinkPickup = identity,
  onEditClickPickup = identity,
  onOpenObservationModal = identity,
  clearObservation = identity,
  onTicketUpdate = identity,
  hasFiscalDuty = false
}) => {
  const [ticketsIds, setTicketIds] = useState([])
  const [isAllSelected, setIsAllSelect] = useState(false)
  const [isVisible, setVisibility] = useState(false)
  const toggleHidden = () => setVisibility(false)
  const isCommercialPartner = useMemo(() => reservation.partnerId && reservation.partnerId === companyId, [reservation, companyId])
  const [activityOpened, setActivityOpened] = useState('')
  const isActivityOpened = serviceId => activityOpened === serviceId
  const onActivityClick = serviceId => () => isActivityOpened(serviceId) ? onActivityTicketsHidden() : setActivityOpened(serviceId)
  const onTicketEdit = useCallback((ticketIds, experienceId) => emitEditEvent(activityOpened, ticketIds, experienceId), [activityOpened, emitEditEvent])
  const isTransport = useMemo(() => activityType === TRANSPORT_TYPE, [activityType])
  const isOfflineExperience = useMemo(() => activityType === OFFLINE_EXPERIENCE_TYPE, [activityType])
  const [isEditingDeadlineNoShow, setIsEditingDeadlineNoShow] = useState(false)
  const editDeadlineNoShow = useCallback(() => setIsEditingDeadlineNoShow(true), [])
  const [isOptionsOpen, setOptionsOpen] = useState(false)
  const [isDiscountOptionOpen, setDiscountOptionOpen] = useState(false)
  const [shouldApplyDiscount, setShouldApplyDiscount] = useState(false)
  const onOptionsOpen = () => setOptionsOpen(true)
  const onDiscountOptionOpen = useCallback(() => setDiscountOptionOpen(true), [])
  const onOptionsClose = () => {
    setOptionsOpen(false)
    setIsEditingDeadlineNoShow(false)
  }
  const onDiscountOptionClose = useCallback(() => {
    setDiscountOptionOpen(false)
  }, [])

  const [isCanceledTicketsBatchConfirmVisible, setCanceledTicketsBatchConfirmVisible] = useState(false)
  const onCanceledTicketsBatchConfirmClose = () => setCanceledTicketsBatchConfirmVisible(false)
  const { onCanceledTickets, loading: canceledTicketsBatchLoading } = useCancelTicketsBatch()

  const onActivityTicketsHidden = () => {
    setActivityOpened('')
    setTicketIds([])
    setIsAllSelect(false)
  }

  const parsedTicketsIds = ticketsIds.map(ticket => ticket?.id)

  const selectedTickets = useMemo(() => tickets.filter(({ id }) => parsedTicketsIds.includes(id)), [tickets, parsedTicketsIds])

  const selectedExperienceId = useMemo(() => selectedTickets.find(ticket => ticket.experienceId)?.experienceId || '', [selectedTickets])

  const areTicketsMixed = useMemo(() => {
    const experienceId = selectedTickets[0]?.experienceId
    return !selectedTickets.every((ticket) => ticket.experienceId === experienceId)
  }, [selectedTickets])

  const { createVoucherUnico, createVoucherTuris, loading: createVoucherLoading } = useCreateVoucher()
  const { cancelVoucherUnico, cancelVoucherTuris } = useCancelVoucher()

  const allTicketsIds = useMemo(() => {
    return tickets.map(ticket => ticket)
      .filter(({ state }) => state !== 'canceled')
      .map(({ id, externalIntegrations }) => ({ id, externalIntegrations }))
  }, [tickets])

  const onTicketSelect = useCallback((id, externalIntegrations) => setTicketIds(previous => {
    const tickets = previous.map(ticket => ticket.id)
    if (tickets.includes(id)) {
      setIsAllSelect(false)
      return previous.filter(ticket => ticket?.id !== id)
    }
    if (previous.length === allTicketsIds.length - 1) {
      setIsAllSelect(true)
    }
    return previous.concat({ id, externalIntegrations })
  }), [allTicketsIds.length])

  const onSelectAll = useCallback(() => setTicketIds(previous => {
    if (previous.length === allTicketsIds.length) {
      setIsAllSelect(false)
      return []
    }
    setIsAllSelect(true)
    return allTicketsIds
  }), [allTicketsIds])

  const isTicketSelected = useMemo(() => (id) => {
    const tickets = ticketsIds.map(ticket => ticket?.id)
    return tickets.includes(id)
  }, [ticketsIds])

  const isTicketDisabled = useCallback((experienceId) => {
    const lastTicket = selectedTickets.slice(-1)[0]

    return (lastTicket && lastTicket.experienceId !== experienceId)
  }, [selectedTickets])

  const hasEditableTickets = useMemo(() => tickets.find(({ state }) => editableTicketStatus.indexOf(state) !== -1), [tickets])

  const someoneHasExternalIntegration = ticketsIds.some(ticket => ticket?.externalIntegrations?.length > 0)

  const toggleVisible = () => setVisibility(true)

  const onConfirm = useCallback(() => {
    const experienceId = shouldApplyDiscount ? selectedExperienceId : ''
    onTicketEdit(parsedTicketsIds, experienceId)
    toggleHidden()
  }, [onTicketEdit, parsedTicketsIds, selectedExperienceId, shouldApplyDiscount])

  const ticketEdit = useCallback((keepDiscount = false) => {
    const experienceId = keepDiscount ? selectedExperienceId : ''
    someoneHasExternalIntegration ? toggleVisible() : onTicketEdit(parsedTicketsIds, experienceId)
  }, [parsedTicketsIds, someoneHasExternalIntegration, onTicketEdit, selectedExperienceId])

  const onOptionClickTicketEdit = useCallback(() => {
    onOptionsClose()

    if (selectedExperienceId) {
      onDiscountOptionOpen()
    } else {
      ticketEdit()
    }
  }, [selectedExperienceId, ticketEdit, onDiscountOptionOpen])

  const onConfirmDiscount = useCallback(() => {
    onDiscountOptionClose()
    setShouldApplyDiscount(true)
    ticketEdit(true)
  }, [ticketEdit, onDiscountOptionClose])

  const onDenyDiscount = useCallback(() => {
    onDiscountOptionClose()
    setShouldApplyDiscount(false)
    ticketEdit(false)
  }, [ticketEdit, onDiscountOptionClose])

  const onCanceledTicketsBatchClick = () => {
    onOptionsClose()
    setCanceledTicketsBatchConfirmVisible(true)
  }

  const onCanceledTicketsBatchConfirm = () => {
    if (hasFiscalDuty) {
      return
    }
    const tickets = parsedTicketsIds.map(id => {
      return {
        ticketId: id,
        reservationId: reservation?.id
      }
    })

    const payload = {
      tickets,
      state: 'canceled'
    }

    onCanceledTicketsBatchConfirmClose()
    onCanceledTickets(payload)
  }

  const ticketIdsWithoutPickup = useCallback(() => {
    const ids = tickets?.map(({ id }) => id)
    const pickupTicketIds = pickupTickets.map(({ ticket }) => ticket?.id)
    const ticketsIds = ids.filter(id => !pickupTicketIds.includes(id))

    return ticketsIds
  }, [tickets, pickupTickets])

  const onLinkPickupBatch = () => {
    const ticketsIds = ticketIdsWithoutPickup()

    onOptionsClose()
    onLinkPickup(ticketsIds, serviceId)
  }

  const isChangeTicketsSlotVisible = parsedTicketsIds.length > 0 && !isOfflineExperience && groupedByService && !areTicketsMixed
  const isCanceledTicketsBatchVisible = parsedTicketsIds.length > 0 && !areTicketsMixed
  const isTicketsPickupBatchVisible = isTransport && groupedByService && ticketIdsWithoutPickup()?.length > 0
  const isOptionsVisible = isChangeTicketsSlotVisible || isCanceledTicketsBatchVisible || isTicketsPickupBatchVisible

  const { id } = useCompany()
  const isDeadlineRuleVisible = useMemo(
    () => {
      const isBonitour = id === '330e4a9b-94cc-450f-bb06-fd64aad0b135'
      return isBonitour || isCommercialPartner
    },
    [isCommercialPartner, id]
  )

  const maxSelectedDeadline = useMemo(
    () => tail(selectedTickets.map(ticket => ticket.deadlineDate).sort()),
    [selectedTickets]
  )

  useEffect(() => {
    setTicketIds([])
  }, [tickets])

  return (
    <>
      <ConfirmDialog
        title='Antes de continuar'
        message='O voucher poderá ser cancelado ao editar o ingresso. Deseja continuar?'
        isVisible={isVisible}
        successCallback={onConfirm}
        cancelCallback={toggleHidden}
        buttonLabelConfirm='Continuar'
        customButtonCss={[dialogButtons]}
      />
      <ConfirmDialog
        title='Cancelar ingressos'
        message='Os ingressos selecionados serão cancelados. Deseja continuar?'
        isVisible={isCanceledTicketsBatchConfirmVisible && !hasFiscalDuty}
        successCallback={onCanceledTicketsBatchConfirm}
        cancelCallback={onCanceledTicketsBatchConfirmClose}
        buttonLabelConfirm='Continuar'
        customButtonCss={[dialogButtons]}
      />
      <Dialog
        customContainercss={[fiscalDutyDialog]}
        isVisible={isCanceledTicketsBatchConfirmVisible && hasFiscalDuty}
        onClose={onCanceledTicketsBatchConfirmClose}
        title='Reserva com NF emitida!'
      >
        <p>Não é possível cancelar ingressos pois a reserva possui nota fiscal emitida.</p>
        <GhostPrimaryButton onClick={onCanceledTicketsBatchConfirmClose}>Entendido</GhostPrimaryButton>
      </Dialog>
      <div>
        {groupedByService && (
          <div css={ticketsContainer} onClick={onActivityClick(serviceId)}>
            <H3>
              <SortRightIcon css={[sortIcon, isActivityOpened(serviceId) && arrowOpenIndicator]} />
              Ingressos
            </H3>
          </div>
        )}

        {(!groupedByService || isActivityOpened(serviceId)) &&
          <>
            <div css={tableContainer}>
              <TableContainer card>
                <TableHeader>
                  <TableHeaderRow>
                    {hasEditableTickets && (
                      <TableHeaderCell css={select}>
                        <Checkbox checked={isAllSelected} onChange={null} onClick={onSelectAll} />
                      </TableHeaderCell>)}
                    <TableHeaderCell>Cód. Ingresso</TableHeaderCell>
                    <TableHeaderCell css={isTransport ? customThTransport : null}>Cód. Externo</TableHeaderCell>
                    <TableHeaderCell>{!groupedByService && 'Atividade, '}Data e Hora</TableHeaderCell>
                    <TableHeaderCell>Viajante</TableHeaderCell>
                    {isTransport && <TableHeaderCell>Local de embarque</TableHeaderCell>}
                    <TableHeaderCell>Status</TableHeaderCell>
                    <TableHeaderCell>Observação</TableHeaderCell>
                    <TableHeaderCell css={alignCenter}>Ação</TableHeaderCell>
                  </TableHeaderRow>
                </TableHeader>
                <TableBody loading={createVoucherLoading || deleteTicketLoading || ticketConfirmLoading || canceledTicketsBatchLoading}>
                  {tickets?.map(ticket => (
                    <TicketRow
                      key={ticket?.id}
                      ticket={ticket}
                      serviceId={serviceId}
                      hasEditableTickets={hasEditableTickets}
                      reservationId={reservationId}
                      isCommercialPartner={isCommercialPartner}
                      pickupTickets={pickupTickets}
                      linkPassengerLoading={linkPassengerLoading}
                      pickupLocalLoading={pickupLocalLoading}
                      isTransport={isTransport}
                      isOfflineExperience={isOfflineExperience}
                      isSelected={isTicketSelected(ticket?.id)}
                      onTicketSelect={onTicketSelect}
                      onTicketDelete={onTicketDelete}
                      setIsAllSelect={setIsAllSelect}
                      onTicketPrint={onTicketPrint}
                      onTicketPrintVoucherTuris={onTicketPrintVoucherTuris}
                      createVoucherUnico={createVoucherUnico}
                      createVoucherTuris={createVoucherTuris}
                      cancelVoucherUnico={cancelVoucherUnico}
                      cancelVoucherTuris={cancelVoucherTuris}
                      onTicketConfirm={onTicketConfirm}
                      onLinkPassenger={onLinkPassenger}
                      onUnlinkPassenger={onUnlinkPassenger}
                      onEditPassenger={onEditPassenger}
                      onLinkPickup={onLinkPickup}
                      onUnlinkPickup={onUnlinkPickup}
                      onEditClickPickup={onEditClickPickup}
                      onOpenObservationModal={onOpenObservationModal}
                      clearObservation={clearObservation}
                      observationLoading={observationLoading}
                      onTicketUpdate={onTicketUpdate}
                      hasFiscalDuty={hasFiscalDuty}
                      isDeadlineVisible={isDeadlineRuleVisible}
                      isDisabled={isTicketDisabled(ticket?.experienceId)}
                    />
                  ))}
                  <TableBodyRow />
                </TableBody>
              </TableContainer>
            </div>

            {isOptionsVisible &&
              <div css={optionsContainerButton}>
                <CircleButton size={50} onClick={onOptionsOpen}>
                  <EllipsisIcon css={optionsIcon} />
                </CircleButton>
              </div>}
          </>}
      </div>

      <Dialog customContainercss={[dialogContainer, isEditingDeadlineNoShow && dialogFullSize]} title={isEditingDeadlineNoShow ? 'Alterar deadline e no-show' : 'Opções'} isVisible={isOptionsOpen} onClose={onOptionsClose}>
        {isEditingDeadlineNoShow
          ? (
            <EditDeadlineNoShow
              initialDateValue={maxSelectedDeadline}
              tickets={selectedTickets}
              closeDialog={onOptionsClose}
              onTicketUpdate={onTicketUpdate}
            />
          )
          : (
            <div css={optionsContainer}>
              {isChangeTicketsSlotVisible && <Label onClick={onOptionClickTicketEdit}>Alterar data e hora dos ingressos selecionados</Label>}
              {isCanceledTicketsBatchVisible && <Label onClick={onCanceledTicketsBatchClick}>Cancelar ingressos selecionados</Label>}
              {isTicketsPickupBatchVisible && <Label onClick={onLinkPickupBatch}>Adicionar local de embarque para os ingressos que ainda não foram definidos</Label>}
              {isDeadlineRuleVisible && <Label onClick={editDeadlineNoShow}>Alterar deadline e no-show dos ingressos selecionados</Label>}
            </div>
          )
        }
      </Dialog>

      <ConfirmDialog
        title='Manter Desconto'
        message='Deseja manter o desconto do combo após a alteração da data e hora dos ingressos selecionados?'
        isVisible={isDiscountOptionOpen}
        onClose={onDiscountOptionClose}
        successCallback={onConfirmDiscount}
        cancelCallback={onDenyDiscount}
        buttonLabelConfirm='Sim'
        buttonLabelCancel='Não'
        customButtonCss={[dialogButtons]}
      />
    </>
  )
}
