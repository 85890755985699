import { BREAK_POINTS, colors } from '@bonitour/components'
import { css } from '@emotion/core'

export const feeRowContainer = css`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  gap: 0.75rem;
  padding: 0.75rem 0 0.75rem 0;

  @media screen and (max-width: ${BREAK_POINTS.bigPhone}) {
    gap: 1rem;
    max-height: 11.875rem;
    overflow-y: auto;
  }
`

export const emphasisInfo = css`
  font-size: 0.9375rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 9.5rem;
  overflow: hidden;
  font-weight: 500;
`

const hoverBtnEffect = (bgColor, fontColor) => css`
  transition-duration: 600ms;
  &:hover {
    background-color: ${bgColor};
    &::after {
      color: ${fontColor};
    }
  }
`

export const counterWrapper = css`
  border: 1px solid ${colors.gray7};
  border-radius: var(--round-element-radius);
  align-items: center;
  background-color: ${colors.gray13};
  max-width: 100%;
  button {
    margin: 0;
    background-color: ${colors.gray13};
    &::after {
      color: ${colors.gray5};
      font-weight: lighter;
    }

    &:first-of-type {
      border-radius: var(--round-element-radius) 0 0 var(--round-element-radius);
      ${hoverBtnEffect(colors.red6, colors.red1)}
    }
    &:last-of-type {
      border-radius: 0 var(--round-element-radius) var(--round-element-radius) 0;
      ${hoverBtnEffect(colors.indigo6, colors.indigo2)}
    }
  }

  input {
    height: 1.88rem;
    padding-top: 0;
    padding-bottom: 0;
  }

  input:hover {
    background-color: ${colors.gray8};
  }
`

export const counterButtons = css`
  background-color: ${colors.gray13};
`

export const feeRow = css`
  display: flex;
  width: calc(100% - 0.65rem);
  justify-content: space-between;

  .fee-details {
    font-size: 0.75rem;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    span:last-of-type {
      margin-top: 0.15rem;
    }
  }

  section {
    display: inline-flex;
    gap: 0.5rem;

    span {
      display: inline-flex;
      align-items: center;
    }
  }

  @media screen and (max-width: ${BREAK_POINTS.bigPhone}) {
    flex-direction: column;
    section {
      justify-content: space-between;
    }
  }
`
