import { css } from '@emotion/core'
import { BREAK_POINTS, colors } from '@bonitour/components'

export const infoTooltipStyle = css`
  margin-left: 5px;
`

export const hide = css`
  visibility: hidden;
`

export const fieldLabelStyle = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  white-space: nowrap;

  > p {
    display: inherit;
    align-items: center;
    color: ${colors.gray6};
    &::before {
      content: ' ';
    }
  }

  @media (max-width: ${BREAK_POINTS.bigPhone}) {
    flex-direction: column;
    align-items: flex-start;
  }
`

export const textareaStyle = css`
  height: 200px;
  min-height: 100px;
  width: 100%;
  resize: vertical;
`

export const inputStyle = css`
  width: 100%;
`

export const tabStyle = css`
  padding: 0.5rem 0.75rem;
  margin: 0 0.25rem;
  font-size: 0.75rem;
  `

export const tabContainerStyle = css`
  align-items: flex-end;
  justify-content: flex-end;
  margin-top: -1.5rem;
  margin-bottom: -1px;

  @media (max-width: ${BREAK_POINTS.tableUp}) {
    margin-top: 0;
  }
`

export const tabPaneStyle = css`
  padding: 0;
  border: none;
`

export const errorTabStyle = css`
  border-color: ${colors.danger};
  color: ${colors.danger};
`

export const warningTabStyle = css`
  border-color: ${colors.orange3};
  color: ${colors.orange3};
`

export const inputGroupStyle = css`
  margin-top: 5px;
  margin-bottom: 15px;
  > div {
    width: 100%;
  }
`
