/* eslint-disable react/jsx-closing-tag-location */
/** @jsxRuntime classic */
/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { identity } from '@bonitour/common-functions'
import { Modal, Logo, CloseIcon, colors, CheckThickIcon, Select, RefreshIcon } from '@bonitour/components'
import { jsx } from '@emotion/core'
import logoOrb from 'assets/svgs/logoOrb.svg'
import { useEffect, useMemo, useState, memo, useCallback } from 'react'
import { PinpadSocketProvider, usePinpadSocket } from './hooks/usePinpadSocket'
import { NewPayAuthApi } from 'services/NewPay/NewPayAuthApi'
import { useTransactions } from './hooks/useTransactions'
import { pinpadContainer } from './PinpadTransaction.styles'
import { useFeatureFlag } from 'contexts/Feature'
import { SOCKET_EVENTS } from './constants/pinpadSocketEvents'
import { usePinpadCookies } from './hooks/usePinpadCookies'

const PinpadIcon = () => {
  return (
    <div aria-hidden="true" className="border pinpad rounded">
      <div className="screen border rounded"/>
      <div className='numpad'>
        {Array.from({ length: 12 }).map((_, i) => (
          <div className="button border rounded" key={`pin_btn_${i}`}/>
        ))}
      </div>
    </div>
  )
}

const ConnectionDynamicIcons = ({ status = 'connecting' }) => {
  switch (status) {
  case 'failedConnection':
  case 'failedPayment':
  case 'pinpadNotFound':
    return (Array.from({ length: 4 }).map((_, i) => <CloseIcon key={`dash_${i}`} />))
  case 'success':
    return <CheckThickIcon />
  default:
    return (Array.from({ length: 5 }).map((_, i) => <div className='dash' key={`dash_${i}`}/>))
  }
}

const MemoizedConnectionDynamicIcons = memo(ConnectionDynamicIcons, (prevProps, nextProps) => {
  return prevProps.status === nextProps.status
})

/* possible status:
    connecting,
    connected,
    failedConnection,
    success,
    failedPayment
*/
const ConectionAnimation = ({ status = 'connecting' }) => {
  const label = useMemo(() => {
    switch (status) {
    case 'connecting': return 'Conectando...'
    case 'connected': return 'Aguardando Pagamento'
    case 'failedConnection': return 'Erro ao conectar'
    case 'failedPayment': return 'Erro ao pagar'
    case 'success': return 'Pagamento Realizado'
    case 'pinpadNotFound': return 'Pinpad não encontrado'
    default: return 'Aguardando Pagamento'
    }
  }, [status])

  const color = useMemo(() => {
    switch (status) {
    case 'connecting':
    case 'success': return colors.green1
    case 'connected': return colors.primary
    case 'failedConnection':
    case 'pinpadNotFound':
    case 'failedPayment': return colors.red1
    default: return colors.primary
    }
  }, [status])

  const hasFailed = useMemo(() => {
    return ['failedConnection', 'failedPayment', 'pinpadNotFound'].includes(status)
  }, [status])

  return (
    <div
      className='connection_animation'
      style={ { '--animation-color': color } }
    >
      <b>{label}</b>
      <div className={hasFailed ? 'failed' : 'dashes'}>
        <MemoizedConnectionDynamicIcons status={status} />
      </div>
    </div>
  )
}

const TryAgainButton = ({ onClick }) => {
  return (
    <div className='try_again_wrapper'>
      <button className="try_again_btn" onClick={onClick}>
      Tentar novamente
      </button>
    </div>
  )
}

const PinpadTransactionModalWithCtx = ({ isVisible, onclose }) => {
  const [selectedPinpad, setSelectedPinpad] = useState(null)
  const [isPaymentRequested, setIsPaymentRequested] = useState(false)
  const [isAuthenticating, setIsAuthenticating] = useState(true)
  const [pinpadEnabled] = useFeatureFlag('orb_pinpad_integration')
  const [hasFailedAuthentication, setHasFailedAuthentication] = useState(false)

  const [paymentResult, setPaymentResult] = useState(null)

  const { pinpadData, updateTransactions } = useTransactions()
  const {
    connect,
    disconnect,
    isConnected,
    emitPaymentRequest,
    hasPaymentError,
    setChargeAlertResponseCallbacks,
    pinpadNotFound
  } = usePinpadSocket()

  useEffect(() => {
    if (!pinpadEnabled) {
      setIsAuthenticating(false)
      setHasFailedAuthentication(true)
      return
    }

    NewPayAuthApi.authenticate()
      .then(() => connect())
      .catch(() => setHasFailedAuthentication(true))
      .finally(() => setIsAuthenticating(false))
    return () => disconnect()
  }, [connect, disconnect, pinpadEnabled])

  useEffect(() => {
    if (!selectedPinpad) return

    if (!isAuthenticating && isConnected && !isPaymentRequested) {
      emitPaymentRequest(pinpadData, selectedPinpad)
      setIsPaymentRequested(true)
    }
  }, [emitPaymentRequest, isConnected, isPaymentRequested, pinpadData, isAuthenticating, selectedPinpad])

  useEffect(() => {
    function onConfirm () {
      setPaymentResult('success')
      updateTransactions()
      setTimeout(onclose, 3500)
    }

    function onFail () {
      setPaymentResult('fail')
    }

    setChargeAlertResponseCallbacks({
      [SOCKET_EVENTS.onPaymentConfirmationAlertToOrb]: onConfirm,
      [SOCKET_EVENTS.onPaymentFailureAlertToOrb]: onFail
    })
  }, [onclose, setChargeAlertResponseCallbacks, updateTransactions])

  const { availablePinpads } = usePinpadCookies()

  const pinpadOptions = useMemo(() => {
    return availablePinpads.map(pinpad => ({
      label: pinpad.alias,
      value: pinpad.totemId
    }))
  }, [availablePinpads])

  const selectedPinpadName = useMemo(() => {
    const selectedPinpadOption = pinpadOptions.find(option => option.value === selectedPinpad)
    return selectedPinpadOption ? selectedPinpadOption.label : ''
  }, [pinpadOptions, selectedPinpad])

  const status = useMemo(() => {
    if (hasFailedAuthentication) return 'failedConnection'
    if (hasPaymentError) return 'failedPayment'
    if (paymentResult === 'success') return 'success'
    if (paymentResult === 'fail') return 'failedPayment'
    if (pinpadNotFound) return 'pinpadNotFound'
    return isConnected ? 'connected' : 'connecting'
  }, [
    hasFailedAuthentication,
    hasPaymentError,
    isConnected,
    paymentResult,
    pinpadNotFound
  ])

  const onTryAgain = useCallback(() => {
    setIsPaymentRequested(false)
    setPaymentResult(null)
  }, [])

  const handlePinpadChange = useCallback((value) => {
    setSelectedPinpad(value)
    setIsPaymentRequested(false)
  }, [])

  return (
    <Modal isVisible={isVisible} title={'Processando Pagamento'} onCloseClick={onclose}>
      <div css={pinpadContainer}>
        {!selectedPinpad
          ? (
            <div className='pinpad_container'>
              <p className='pinpad_select_label'>Selecione um pinpad para continuar</p>
              {pinpadOptions.length === 0
                ? (
                  <div className='no_pinpad'>
                    <b>Nenhum pinpad disponível</b>
                    <span>(Configure seus Pinpads no seu PAY)</span>
                  </div>
                )
                : (
                  <Select onChange={handlePinpadChange}
                    options={pinpadOptions}
                    value={selectedPinpad}
                    placeholder='Selecione o pinpad aqui'
                  />
                )}
            </div>
          )
          : (
            <div className='pinpad_container'>
              <p className='pinpad_info'>
                Utilize o pinpad <b>{selectedPinpadName}</b> para continar
              </p>
              <button type='button' className='pinpad_change' onClick={() => setSelectedPinpad(null)}>
                <RefreshIcon/> Alterar Pinpad
              </button>
              <div className="pinpad_content">
                <Logo src={logoOrb} />
                <ConectionAnimation status={status} />
                <PinpadIcon/>
              </div>
              {status === 'failedPayment' && <TryAgainButton onClick={onTryAgain}/>}
            </div>
          )}
      </div>
    </Modal>
  )
}

export const PinpadTransactionModal = ({
  isVisible,
  onclose = identity
}) => {
  return (
    <PinpadSocketProvider>
      <PinpadTransactionModalWithCtx
        isVisible={isVisible}
        onclose={onclose}
      />
    </PinpadSocketProvider>
  )
}
