/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { TicketActivity } from 'containers/Reservation/TicketActivity'
import { identity } from '@bonitour/common-functions'
import { useCallback, useEffect, useState } from 'react'
import { BookingMapModal } from 'domains/Booking/Map/BookingMapModal'
import { TicketSummary } from './Tickets/TicketSummary'
import { BookingEditionMapModal } from 'domains/Booking/Map/BookingEditionMapModal'
import { useTicketEdit } from './Tickets/context/TicketEditContext'
import { usePassenger } from '../Passenger/hooks/usePassenger'
import { LinkPassengerSelection } from '../Passenger/LinkPassengerSelection'
import { PassengerFormModal } from '../Passenger/PassengerFormModal'
import { usePickup } from '../Pickup/hooks/usePickup'
import { PickupModal } from '../Pickup/PickupModal'
import { useToast } from '@bonitour/components'
import { ObservationModal } from '../Observation/ObeservationModal'
import { useObservation } from '../Observation/hooks/useObservation'
import { BookingMapInfoProvider } from 'domains/Booking/Map/contexts/useBookingMapInfo'
import { AutoFillPickupModal } from '../Pickup/AutoFillPickupModal'
import { useServicePickUpAddresses } from 'hooks/domains/useServicePickUpAddresses'
import { useQuery } from 'hooks/useQuery'
import { useActivity } from 'contexts/Activity'

export const ReservationTicketApp = ({
  tickets = {},
  reservationTickets = [],
  reservation = [],
  reservationPassengers = [],
  total = 0,
  reservationId = '',
  reservationCode = '',
  companyId = '',
  deleteTicketLoading = false,
  ticketConfirmLoading = false,
  reservationPassengersLoading = false,
  reservationTicketLoading = false,
  onTicketDelete = identity,
  onTicketPrint = identity,
  onTicketPrintVoucherTuris = identity,
  onTicketConfirm = identity,
  fetchReservationPassengers = identity,
  fetchReservationTickets = identity,
  updateReservation = identity
}) => {
  const [{
    isOpenedBookingMapModal,
    activityId
  }, {
    removeQueriesParam
  }] = useQuery()
  const { add: addToast } = useToast()
  const [isModalVisible, setModalVisibility] = useState(false)
  const toggleModalVisibility = () => setModalVisibility((previous) => !previous)
  const onModalClose = useCallback(() => {
    setModalVisibility(false)
    removeQueriesParam([
      'isOpenedBookingMapModal',
      'activityId',
      'slots',
      'slotsKey'
    ])
  }, [removeQueriesParam])
  const {
    isTicketEditModalVisible,
    ticketList,
    editServiceId,
    editExperienceId,
    openEditionModal,
    closeEditionModal,
    onEditionSubmitted
  } = useTicketEdit()
  const {
    passenger,
    passengersFound,
    onPassengerSelection,
    isLinkPassengerModalVisible,
    isPassengerFormVisible,
    isEditPassenger,
    searchPassengerLoading,
    linkPassengerLoading,
    onCloseLinkPassengerModal,
    onClickNewPassenger,
    onClickNewDependent,
    onClosePassengerForm,
    onBackPassengerForm,
    onCreatePassenger,
    onEditPassenger,
    onLinkPassenger,
    onSearchPassengers,
    onUnlinkPassenger,
    onClickEditPassenger
  } = usePassenger(reservation, fetchReservationTickets)
  const {
    isPickupModalVisible,
    onClosePickupModal,
    onClickPickup,
    onUnlinkPickup,
    editPickup,
    getPickup,
    pickupTickets,
    loading: pickupLocalLoading,
    onEditClickPickup,
    pickup,
    setPickup,
    createPickup,
    isPickupCreationOrEdition,
    isPickupAutoFillModalVisible,
    onOpenAutoFillModal,
    onCloseAutoFillModal,
    ticketIds: selectedIds,
    serviceId: selectedServiceId
  } = usePickup(fetchReservationTickets)
  const { setId } = useActivity()

  useEffect(() => {
    if (isOpenedBookingMapModal === 'true' && activityId) {
      setId(activityId)
      if (!isModalVisible) {
        setModalVisibility(true)
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { linkedAddresses, isLoading: isServicePickUpAddressesLoading } = useServicePickUpAddresses({
    serviceId: selectedServiceId
  })

  const {
    isObservatioModalVisible,
    onCloseObservationModal,
    onOpenObservationModal,
    updateObservation,
    observation,
    loading: observationLoading
  } = useObservation(reservationTickets, fetchReservationTickets)

  const onSuccess = async (data) => {
    try {
      onClosePickupModal()
      isPickupCreationOrEdition === 'create' ? await createPickup(data) : await editPickup(data)
      fetchPickupDropoff()
      addToast('Local definido com sucesso', 'success')
    } catch {
      addToast('Ocorreu um erro na definição do local')
    }
  }

  const clearObservation = useCallback((id) => updateObservation('', id), [updateObservation])

  const onUpdateObservation = useCallback((form) => updateObservation(form.observation), [updateObservation])

  useEffect(() => {
    fetchReservationTickets()
  }, [fetchReservationTickets])

  useEffect(() => {
    reservationId && fetchReservationPassengers(reservationId)
  }, [fetchReservationPassengers, reservationId, isLinkPassengerModalVisible])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchPickupDropoff = useCallback(() => getPickup(), [])

  const handleClouseAutoFillModal = useCallback(() => {
    onCloseAutoFillModal()
    setPickup({})
  }, [setPickup, onCloseAutoFillModal])

  const onFillPickUpManually = useCallback(() => {
    onClickPickup(selectedIds, selectedServiceId)
    handleClouseAutoFillModal()
  }, [handleClouseAutoFillModal, onClickPickup, selectedIds, selectedServiceId])

  const onSelectAutoFillAddress = useCallback(address => {
    const newPickUpAddress = {
      name: address.name,
      addressInfo: {
        address: address.street,
        addressDetail: address.supplement,
        zipCode: address.postCode,
        district: address.district,
        country: address.countryId,
        state: address.stateId,
        city: address.cityId
      },
      selectedServiceId
    }
    setPickup(newPickUpAddress)
    onCloseAutoFillModal()
    onClickPickup(selectedIds, selectedServiceId)
  }, [onClickPickup, onCloseAutoFillModal, selectedIds, selectedServiceId, setPickup])

  const onTicketUpdate = useCallback(() => {
    fetchReservationTickets()
    updateReservation()
  }, [fetchReservationTickets, updateReservation])

  useEffect(() => {
    fetchPickupDropoff()
  }, [fetchPickupDropoff])

  return (
    <>
      <TicketSummary
        total={total}
        reservation={reservation}
        onActivitySelect={toggleModalVisibility}
        onTicketUpdate={onTicketUpdate}
      />
      <TicketActivity
        onTicketDelete={onTicketDelete}
        onTicketUpdate={onTicketUpdate}
        deleteTicketLoading={deleteTicketLoading}
        reservationTicketLoading={reservationTicketLoading}
        onEdit={openEditionModal}
        reservationTickets={reservationTickets}
        onTicketPrint={onTicketPrint}
        onTicketPrintVoucherTuris={onTicketPrintVoucherTuris}
        reservation={reservation}
        companyId={companyId}
        onTicketConfirm={onTicketConfirm}
        onLinkPassenger={onPassengerSelection}
        onUnlinkPassenger={onUnlinkPassenger}
        onEditPassenger={onClickEditPassenger}
        onLinkPickup={onOpenAutoFillModal}
        onUnlinkPickup={onUnlinkPickup}
        onEditClickPickup={onEditClickPickup}
        ticketConfirmLoading={ticketConfirmLoading}
        linkPassengerLoading={linkPassengerLoading}
        pickupLocalLoading={pickupLocalLoading || isServicePickUpAddressesLoading}
        reservationId={reservationId}
        pickupTickets={pickupTickets}
        onOpenObservationModal={onOpenObservationModal}
        clearObservation={clearObservation}
        observationLoading={observationLoading}
      />
      <BookingMapInfoProvider isCommonBookingMap={false}>
        <BookingMapModal
          date={tickets.firstDate}
          onModalClose={onModalClose}
          experienceId={editExperienceId}
          reservationCode={reservationCode}
          isVisible={isModalVisible}
        />
      </BookingMapInfoProvider>
      <BookingEditionMapModal
        date={tickets.firstDate}
        isVisible={isTicketEditModalVisible}
        onModalClose={closeEditionModal}
        serviceId={editServiceId}
        ticketsIds={ticketList}
        experienceId={editExperienceId}
        reservationCode={reservationCode}
        onEditionSubmitted={onEditionSubmitted}
        reservationTickets={reservationTickets}
        selectedTicketsIds={ticketList}
      />
      <LinkPassengerSelection
        reservation={reservation}
        reservationPassengers={reservationPassengers}
        reservationPassengersLoading={reservationPassengersLoading}
        isVisible={isLinkPassengerModalVisible}
        onClickNewPassenger={onClickNewPassenger}
        onClickNewDependent={onClickNewDependent}
        onClose={onCloseLinkPassengerModal}
        onLinkPassenger={onLinkPassenger}
        onSearchPassengers={onSearchPassengers}
        passengersFound={passengersFound?.passengers}
        searchPassengerLoading={searchPassengerLoading}
      />
      <PassengerFormModal
        passenger={passenger}
        isVisible={isPassengerFormVisible}
        isPersonalFieldsDisabled={isEditPassenger}
        onClose={onClosePassengerForm}
        onBack={isEditPassenger ? onClosePassengerForm : onBackPassengerForm}
        onSubmit={isEditPassenger ? onEditPassenger : onCreatePassenger}
      />

      <AutoFillPickupModal
        servicePickUpAddresses={linkedAddresses}
        isLoading={isServicePickUpAddressesLoading}
        isVisible={isPickupAutoFillModalVisible}
        onSelect={onSelectAutoFillAddress}
        onClose={handleClouseAutoFillModal}
        onFillManually={onFillPickUpManually}
      />

      <PickupModal
        isPickupModalVisible={isPickupModalVisible}
        onClose={onClosePickupModal}
        onSuccess={onSuccess}
        pickup={pickup}
        isCoordinatesDisabled
      />

      <ObservationModal
        isObservationModalVisible={isObservatioModalVisible}
        onClose={onCloseObservationModal}
        onSuccess={onUpdateObservation}
        observation={observation}
      />
    </>
  )
}
