/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */

import { Card, Column, H3, InputFormGroup, LoadingAnimation, Row } from '@bonitour/components'
import { jsx } from '@emotion/core'
import { pickupPlaceByServiceCardStyle, pickupPlaceByServiceWrapperStyle } from './CombinedExperienceForm.styles'
import { marginBottom } from 'assets/styles/global'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useActivity } from 'contexts/Activity'
import { SafeJSONParse } from 'utils/object'
import { ActivityCard } from 'containers/Activity/ActivityCard'
import { ServicePickUpAddressesService } from 'core/services/ServicePickUpAddress'
import { useQuery } from 'react-query'
import { CombinedExperienceFormPickupService } from './CombinedExperienceFormPickupService'
import { useFeatureFlag } from 'contexts/Feature'

export const CombinedExperienceFormPickup = ({
  register,
  errors,
  isLoading,
  control,
  selectedServices,
  setValue,
  watch
}) => {
  const {
    loading: isLoadingExperienceList,
    companyActivities = [],
    partnerActivities = []
  } = useActivity()

  const experienceList = useMemo(
    () => companyActivities?.concat(partnerActivities),
    [companyActivities, partnerActivities]
  )

  const selectedServicesIds = useMemo(
    () => selectedServices?.map(({ id }) => id),
    [selectedServices]
  )

  const [isOrbPickupPlacesEnabled] = useFeatureFlag('orb-transport-pickup-selector')

  const {
    data: linkedPickupAddressess,
    isLoading: isLoadingLinkedPickupAddressess
  } = useQuery({
    queryKey: ['linkedPickupAddressess', ...selectedServicesIds],
    enabled: !isLoadingExperienceList,
    queryFn: () => {
      if (!isOrbPickupPlacesEnabled) {
        return Promise.resolve({})
      }
      return Promise.all(
        selectedServicesIds.map(
          (serviceId) => ServicePickUpAddressesService.linkedAddresses({ serviceId })
            .then((data) => [serviceId, data])
        )
      ).then((data) => Object.fromEntries(data))
    },
    retry: 1,
    refetchOnWindowFocus: false
  })

  const selectedServicesWithDetails = useMemo(
    () => {
      if (isLoadingExperienceList) {
        return []
      }
      return selectedServices?.map((service) => {
        const { name, companyName, image, description } = experienceList?.find((exp) => exp?.id === service?.id) || {}
        const limberPickupOptions = SafeJSONParse(description, null)?.limber_data?.locaisEmbarque?.map(
          ({ localEmbarque, nomeLocalEmbarque, descricao }) => ({
            pickupPlaceId: String(localEmbarque),
            name: nomeLocalEmbarque,
            description: descricao
          })
        ) || []
        const orbPickupOptions = linkedPickupAddressess?.[service.id]?.map(
          ({ id, name, ...address }) => ({
            pickupPlaceId: id,
            name,
            description: `${
              address?.street || ''
            }${
              address?.street && (address?.supplement || address?.district) ? ', ' : ''
            }${
              address?.supplement || ''
            }${
              address?.supplement && address?.district ? ' - ' : ''
            }${
              address?.district || ''
            }`
          })
        ) || []
        return {
          ...service,
          name,
          companyName,
          image,
          pickupOptions: limberPickupOptions.concat(orbPickupOptions)
        }
      })
    },
    [selectedServices, isLoadingExperienceList, linkedPickupAddressess, experienceList]
  )

  const pickupOptions = watch('pickupOptions')

  useEffect(() => {
    if (!isLoadingExperienceList) {
      Object.keys(pickupOptions || {}).forEach((serviceId) => {
        const service = selectedServicesWithDetails.find(({ id }) => id === serviceId)
        if (!service) {
          setValue('pickupOptions', { ...pickupOptions, [serviceId]: undefined })
        }
      })
    }
  }, [pickupOptions, selectedServicesWithDetails, setValue, isLoadingExperienceList])

  const [openPickupDialogId, setOpenPickupDialogId] = useState(null)
  const openPickupDialog = useCallback((id) => () => setOpenPickupDialogId(id), [])
  const closePickupDialog = useCallback(() => setOpenPickupDialogId(null), [])
  const isPickupDialogOpen = useCallback((id) => openPickupDialogId === id, [openPickupDialogId])

  if (!selectedServicesWithDetails?.length) {
    return null
  }

  if (isLoading || isLoadingExperienceList || isLoadingLinkedPickupAddressess) {
    return <LoadingAnimation size={50} customCss={[marginBottom(30)]} />
  }

  return (
    <>
      <InputFormGroup
        label=' '
        errorMessage={errors?.title?.feeMap}
      />
      <H3 css={marginBottom(0)}>Locais de embarque</H3>
      <Column phone={12} desktop={12} css={marginBottom(20)}>
        <Row style={{ width: '100%' }}>
          <div css={pickupPlaceByServiceWrapperStyle} style={{ width: '100%' }}>
            {selectedServicesWithDetails?.map((service) => (
              <Card key={service?.id} customCss={[pickupPlaceByServiceCardStyle]} onClick={openPickupDialog(service.id)}>
                <InputFormGroup
                  label={
                    <ActivityCard
                      activity={service}
                      hideActionLabel
                      hasTitleMaxWidth
                      disableLink
                      hideTypeLabel
                      disableOnClick
                    />
                  }
                  errorMessage={errors?.pickupOptions?.[service.id]?.length && 'Erros no formulário'}
                />
                <CombinedExperienceFormPickupService
                  register={register}
                  errors={errors}
                  isLoading={isLoading || isLoadingLinkedPickupAddressess || isLoadingExperienceList}
                  control={control}
                  service={service}
                  pickupOptions={service?.pickupOptions}
                  isOpen={isPickupDialogOpen(service.id)}
                  onClose={closePickupDialog}
                />
              </Card>
            ))}
          </div>
        </Row>
      </Column>
    </>
  )
}
