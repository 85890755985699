/** @jsxRuntime classic */
/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { jsx } from '@emotion/react'

import { feeRowContainer, emphasisInfo, counterWrapper, counterButtons, feeRow } from './ActivityFees.style'
import { formatMoney, identity } from '@bonitour/common-functions'
import { Counter } from '@bonitour/components'

export const ActivityFeesContainer = ({
  children
}) => {
  return (<div css={feeRowContainer}>
    {children}
  </div>)
}

export const ActivityFeePaxDetails = ({
  feeData
}) => {
  const {
    name = null,
    minAge = 0,
    maxAge = 0
  } = feeData

  return (<div className='fee-details'>
    <span css={emphasisInfo}>{name}</span>
    <span>{minAge} - {maxAge} anos</span>
  </div>)
}

export const ActivityFeeSelector = ({
  feePricing = 0,
  selectedQuantity = 0,
  limitOfDay = 0,
  canDisableAddBtn = false,
  handleCounterChange
}) => {
  return (
    <section>
      <span css={emphasisInfo}>
        {formatMoney(parseFloat(feePricing).toFixed(2))}
      </span>
      <div css={counterWrapper}>
        <Counter
          css={counterButtons}
          value={selectedQuantity}
          min={0}
          max={limitOfDay}
          onChange={handleCounterChange(selectedQuantity)}
          isDisabledAddButton={canDisableAddBtn}
          isDisabledSubtractButton={selectedQuantity < 1}
          isReadOnlyInput={false}
        />
      </div>
    </section>
  )
}

export const ActivityFeeSelectorCard = ({
  onCounterChange = identity,
  feeData = {},
  canDisableAddBtn = false,
  selectedQuantity = 0,
  limitOfDay = 0
}) => {
  return (<div css={feeRow}>
    <ActivityFeePaxDetails feeData={feeData}/>
    <ActivityFeeSelector
      feePricing={feeData.pricing}
      selectedQuantity={selectedQuantity}
      limitOfDay={limitOfDay}
      handleCounterChange={onCounterChange}
      canDisableAddBtn={canDisableAddBtn}
    />
  </div>)
}
