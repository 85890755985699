import { useEffect, useMemo, useState } from 'react'
import { useToast } from '@bonitour/components'

import { OrbStore } from 'services/Orb/Store'

const pinpadCookies = {
  get: () => {
    const pinpadCookies = OrbStore.get('available_pinpads')
    if (!pinpadCookies) return []
    try {
      const parsedCookies = JSON.parse(pinpadCookies)
      if (!Array.isArray(parsedCookies)) {
        console.error('Pinpad cookies are not in the expected format')
        return []
      }
      return parsedCookies.map((cookie) => ({
        alias: cookie.alias || '???',
        totemId: cookie.totemId
      }))
    } catch (error) {
      console.error('Error parsing pinpad cookies:', error)
      return []
    }
  }
}

export const usePinpadCookies = () => {
  const [alreadyAlerted, setAlreadyAlerted] = useState(false)

  const { add: addToast } = useToast()

  const availablePinpads = useMemo(() => pinpadCookies.get(), [])

  useEffect(() => {
    if (availablePinpads.length === 0 && !alreadyAlerted) {
      addToast('Nenhum pinpad disponível. Registre novos pinpads no Pay antes de continuar', 'warning')
      setAlreadyAlerted(true)
    }
  }
  , [availablePinpads, alreadyAlerted, addToast])

  return { availablePinpads }
}
