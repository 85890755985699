/** @jsxRuntime classic */
/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { jsx, css } from '@emotion/react'

import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { ActivityModalHeader, ActivityFeeSelectorCard, ActivityFeesContainer } from '../ActivityModalParts'

import { useDebounce } from '@bonitour/app-functions'
import { identity } from '@bonitour/common-functions'
import { colors, Label, useToast, WarningIcon } from '@bonitour/components'

export const sameDayLabelCss = css`
 i {
  margin-right: 0.5rem;
 }
 color: ${colors.gray4};
 width: 100%;
 text-align: left;
 padding-left: 1rem;
 padding-bottom: 0.5rem;
`

export const CombinedExperiencePaxSelector = memo(({
  onSelectActivity = identity,
  getSelectedPaxData = identity,
  experience = {},
  slotsOccupiedOnDate = 0,
  slotsOccupiedOnHour = 0,
  activityIndex = 0,
  startTime = null,
  endTime = null,
  color = null,
  limitOfDay = null,
  date = null,
  selectedDate = null
}) => {
  const { add: addToast } = useToast()

  const {
    reservationsTotal = 0,
    slotsAvailable = 0
  } = experience || {}

  const initialData = useMemo(() => getSelectedPaxData({
    activityId: experience?.id,
    date,
    hour: startTime
  }), [getSelectedPaxData, experience?.id, date, startTime])

  const [quantity, setQuantity] = useState(initialData)
  const debouncedPaxSelection = useDebounce(quantity, 600)

  const handleCounterChange = useCallback((feeName) => (newQuantity) => setQuantity(prevQuantity => ({
    ...prevQuantity,
    [feeName]: newQuantity
  })), [])

  const updatePaxSelection = useCallback((paxSelection) => {
    Object.keys(paxSelection)
      .forEach((feeName) => {
        const ticketQuantity = Number(paxSelection[feeName])

        const selectionDto = {
          id: experience?.id,
          hour: startTime,
          date,
          feeName,
          quantity: isNaN(ticketQuantity) ? '' : ticketQuantity,
          limitOfDay
        }
        const { isAdded, extra = null, currentQuantity } = onSelectActivity(selectionDto)

        if (!isAdded) {
          addToast('Limite diário de vagas excedido ' + extra, 'error')
          handleCounterChange(feeName)(currentQuantity)
        }
      })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedPaxSelection])
  useEffect(() => {
    if (JSON.stringify(debouncedPaxSelection) === JSON.stringify(initialData)) return
    updatePaxSelection(debouncedPaxSelection)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatePaxSelection])

  const componentWillUnmount = useRef(false)
  useEffect(() => () => {
    componentWillUnmount.current = true
  }, [])
  useEffect(() => () => {
    if (componentWillUnmount.current && JSON.stringify(quantity) !== JSON.stringify(debouncedPaxSelection)) {
      updatePaxSelection(quantity)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatePaxSelection, quantity])

  const [pendingDelta, setPendingDelta] = useState(0)
  const [pendingDeltaActions, setPendingDeltaActions] = useState([])
  useEffect(() => {
    setPendingDelta(0)
    setPendingDeltaActions([])
  }, [limitOfDay, slotsOccupiedOnDate])

  const isReachedAvailableSlots = useMemo(() => {
    const occupiedSlots = pendingDelta + slotsOccupiedOnHour

    return (slotsAvailable - occupiedSlots) <= 0
  }, [pendingDelta, slotsAvailable, slotsOccupiedOnHour])

  const isReachedLimitOfDay = useMemo(() => {
    const occupiedSlots = pendingDelta + slotsOccupiedOnDate

    const totalSlots = Math.abs(limitOfDay - reservationsTotal)

    return (totalSlots - occupiedSlots) === 0
  }, [pendingDelta, limitOfDay, reservationsTotal, slotsOccupiedOnDate])

  const safeCounterChange = useCallback((feeName) => (prevValue) => (value) => {
    const maxDelta = Math.min(limitOfDay - slotsOccupiedOnDate, (slotsAvailable - prevValue))

    const delta = value - prevValue
    const currentFeePendingDelta = pendingDeltaActions
      .reduce((acc, action) => action.feeName === feeName ? acc + action.delta : acc, 0)

    if ((pendingDelta - currentFeePendingDelta + delta) > maxDelta) {
      return
    }

    setPendingDelta(pending => pending + delta)
    setPendingDeltaActions(counters => [
      ...counters,
      {
        feeName,
        delta
      }
    ])
    return handleCounterChange(feeName)(value)
  }, [handleCounterChange, limitOfDay, pendingDelta, slotsAvailable, slotsOccupiedOnDate, pendingDeltaActions])

  return (
    <>
      {experience
        ? <ActivityModalHeader
          color={color}
          experience={experience}
          startTime={startTime}
          endTime={endTime}
          activityIndex={activityIndex}
        />
        : null}
      {((selectedDate && date === selectedDate) || !selectedDate)
        ? (
          <ActivityFeesContainer>
            {experience?.fees.map((fee, index) => <ActivityFeeSelectorCard
              feeData={fee}
              limitOfDay={limitOfDay}
              slotsOccupiedOnDate={slotsOccupiedOnDate}
              selectedQuantity={quantity?.[fee.name]}
              onCounterChange={safeCounterChange(fee.name)}
              key={`pax-selector-card-#${activityIndex}-${index}`}
              canDisableAddBtn={isReachedLimitOfDay || isReachedAvailableSlots}
            />)}
            {!experience?.fees?.length && (
              <Label css={sameDayLabelCss}>
                <WarningIcon />
                Não há tarifários disponíveis
              </Label>
            )}
          </ActivityFeesContainer>
        )
        : (
          <Label css={sameDayLabelCss}>
            <WarningIcon />
        Os serviços devem ocorrer no mesmo dia
          </Label>
        )}
    </>
  )
})
