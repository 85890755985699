import { useCallback, useMemo } from 'react'
import { useQuery } from 'react-query'
import { ReservationMapService } from 'services/ReservationMap/Service'

import { useActivity } from 'contexts/Activity'

import { useDebounce } from '@bonitour/app-functions'
import { useToast } from '@bonitour/components'
import { head, tail } from '@bonitour/common-functions'

import { COMBINED_EXPERIENCE_TYPE } from 'constants/activityTypes'
import { colorCycle } from 'constants/combinedExperience'

const MAP_INTERVAL = 90000

export const useMapSubscriber = (dateColumns, interval = true, pickupPlaceId, pickupPlaceIdByService, experienceId = '') => {
  const debouncedDates = useDebounce(dateColumns, 750)
  const { companyActivities = [], partnerActivities = [], activity = {} } = useActivity()

  const { id: serviceId, companyId, experiences = [], type } = activity

  const {
    add: addToast
  } = useToast()

  const isCombinedExperience = useMemo(() => type === COMBINED_EXPERIENCE_TYPE, [type])

  const experienceServices = useMemo(() => {
    if (!isCombinedExperience || !activity) return null
    return experiences
  }, [activity, experiences, isCombinedExperience])

  const fetchCombinedExperienceServicesListData = useCallback(async () => {
    const promises = experienceServices.map((experienceService, serviceIndex) => ReservationMapService.getMap({
      serviceId: experienceService.serviceId,
      companyId: experienceService.companyId,
      startDate: head(debouncedDates),
      endDate: tail(debouncedDates),
      type: experienceService.type,
      pickupPlaceId: pickupPlaceIdByService?.[experienceService.serviceId],
      experienceId: activity.id
    })
      .then((response) => {
        const { duration = '', name = '', image = null } = [partnerActivities, companyActivities]
          .flat()
          .find(({ id }) => id === experienceService.serviceId)

        return {
          ...response,
          id: experienceService.serviceId,
          color: colorCycle[serviceIndex % colorCycle.length],
          duration,
          name,
          image,
          isOptional: experienceService.isOptional,
          companyId: experienceService.companyId,
          companyName: experienceService.companyName
        }
      }))

    return Promise.all(promises)
  }, [activity.id, companyActivities, debouncedDates, experienceServices, partnerActivities, pickupPlaceIdByService])

  const {
    data: vacanciesData,
    isLoading,
    isFetching
  } = useQuery({
    refetchInterval: interval ? MAP_INTERVAL : null,
    queryKey: ['reservationMap', debouncedDates, serviceId, companyId, pickupPlaceId, pickupPlaceIdByService, experienceId],
    queryFn: () => {
      if (!serviceId || !companyId || !debouncedDates.length) {
        return Promise.resolve({})
      }

      if (isCombinedExperience) {
        return fetchCombinedExperienceServicesListData()
      }

      return ReservationMapService.getMap({
        serviceId,
        companyId,
        startDate: head(debouncedDates),
        endDate: tail(debouncedDates),
        type,
        pickupPlaceId,
        experienceId
      })
    },
    onError: () => {
      addToast('Houve um problema ao carregar as vagas disponíveis')
    },
    retry: 1
  })

  const {
    registries,
    prices
  } = useMemo(() => ({
    registries: isCombinedExperience ? [] : vacanciesData?.registries || [],
    prices: isCombinedExperience ? [] : vacanciesData?.prices || []
  }), [isCombinedExperience, vacanciesData])

  const activitiesColors = useMemo(() => {
    if (isLoading || !isCombinedExperience || !Array.isArray(vacanciesData)) return []
    return vacanciesData.map(({ color }) => color)
  }, [isCombinedExperience, isLoading, vacanciesData])

  return {
    registries,
    prices,
    isLoading,
    isFetching,
    activitiesRegistryAndPrice: isCombinedExperience ? vacanciesData : null,
    activitiesColors,
    experienceServices,
    debouncedDates
  }
}
