import { BREAK_POINTS, colors } from '@bonitour/components'
import { css } from '@emotion/core'

export const pinpadContainer = css`
  display: flex;
  flex-direction: column;
  align-items: center;

  .try_again_wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .try_again_btn {
    margin: 0 auto;
    margin-top: -2rem;
    background-color: ${colors.primary};
    color: ${colors.white};
    border: 0;
    border-radius: 4px;
    font-size: 1.125rem;
    font-weight: 600;
    padding: 1rem 2rem;
    cursor: pointer;
  }

  .pinpad_change {
    background-color: transparent;
    border: 0;
    border-bottom: 1px solid ${colors.primary};
    color: ${colors.primary};
    padding: 0;
    cursor: pointer;
  }

  .pinpad_container {
    width: 100%;
    min-height: 30vh;

    .pinpad_select_label {
      font-size: 1rem;
      font-weight: 500;
      margin-top: 0;
      margin-bottom: 1rem;
    }

    .no_pinpad {
      font-size: 2rem;
      color: ${colors.gray5};
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      text-align: center;
      margin: 5rem auto;
      gap: 1rem;

      span {
        font-size: 0.9rem;
      }
    }

    > .pinpad_info {
      font-size: 0.9rem;
      font-weight: 600;
      color: ${colors.primary};
      margin-top: 0;

      b {
        display: inline-block;
        padding: 0.25rem 0.5rem;
        background-color: ${colors.primary};
        border-radius: 0.25rem;
        color: ${colors.white};
        font-weight: 600;
      }
    }
  }

  .pinpad_content {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    max-width: 700px;
    margin: 0 auto;

    box-sizing: border-box;

    *, *::before, *::after {
      box-sizing: border-box;
    }

    img {
      width: 250px;
      height: auto;
    }



    .connection_animation {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 0.5rem;

      b {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0.25rem 0.5rem;
        background-color: var(--animation-color);
        color: ${colors.white};
        border-radius: 4px;
        font-weight: 500;
      }
    }

    .dashes {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 150px;
      margin: 0 2rem;
    }

    .dash {
      display: block;
      width: 20px;
      height: 10px;
      border-radius: 99vw;
      background-color: var(--animation-color);
      animation: pulse 1s infinite ease-in-out;
    }

    .pinpad {
      width: 60px;
      height: 110px;
      padding: 4px;
    }

    .screen {
      width: 100%;
      height: 40px;
      border-radius: 8px;
    }

    .numpad {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 4px;
      width: 100%;
      margin-top: 8px;
    }

    .button {
      height: 8px;
    }

    .border {
      border: 3px solid ${colors.primary};
    }

    .rounded {
      border-radius: 8px;
    }

    .failed {
      font-size: 2rem;
      width: 150px;
      margin: 0 2rem;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      color: var(--animation-color);
    }



    @keyframes pulse {
      0%, 100% {
        transform: scale(1);
        opacity: 0.5;
      }
      50% {
        transform: scale(1.2);
        opacity: 1;
      }
    }

    /* Staggered animation delays */
    .dash:nth-child(1) { animation-delay: 0s;   }
    .dash:nth-child(2) { animation-delay: 0.2s; }
    .dash:nth-child(3) { animation-delay: 0.4s; }
    .dash:nth-child(4) { animation-delay: 0.6s; }
    .dash:nth-child(5) { animation-delay: 0.8s; }

    @media (max-width: ${BREAK_POINTS.smallTablet}) {
      flex-direction: column;
      gap: 2rem;
    }
  }

`
