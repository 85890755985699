/* eslint-disable react/no-unknown-property */
/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useCallback, useMemo, useRef, useState } from 'react'
import {
  TableBodyRow,
  TableBodyCell,
  Checkbox,
  Button,
  useToast,
  InfoSwatch,
  flexHorizontallyCentered,
  Dialog as DialogComponent,
  TruncateCell,
  GhostPrimaryButton
} from '@bonitour/components'
import { capitalize, cursorAuto, marginTop } from 'assets/styles/global'
import { exportPdf, head, identity, tail, formatDate } from '@bonitour/common-functions'
import { TicketStatus } from './TicketStatus'
import { ticketStatusDictionary } from 'constants/ticketStatus'
import {
  cellFontSize,
  externalCodeCellContainer,
  voucherContainerStyle,
  select,
  OfflineTicketEditionDialogContainer,
  externalCodeBtnsContainer,
  marginLeft5,
  nameDateTimeCell,
  tooltipWithDeadlineStyle,
  dateTimeCell,
  customDeadlineTooltip,
  deadLineSummaryStyle
} from './TicketRow.style'
import {
  CREATE_VOUCHER,
  CANCEL_TICKET,
  CONFIRM_RESERVATION,
  CREATE_VOUCHER_TURIS,
  CANCEL_TICKET_TURIS
} from './dialog.states'
import { ConfirmTicket } from './ConfirmTicket'
import { InsertCodeDialog } from './InsertCodeDialog'
import { useExternalIdIntegration } from './hooks/useExternalIdIntegration'
import { LinkPassengerButton } from './TicketRow/LinkPassengerButton/LinkPassengerButton'
import { ProcessingContainer } from './TicketRow/ProcessingContainer/ProcessingContainer'
import { GeneratedVoucher } from './TicketRow/GeneratedVoucher/GeneratedVoucher'
import { Dialog } from './TicketRow/Dialog/Dialog'
import { VoucherInsert } from './TicketRow/VoucherInsert/VoucherInsert'
import { useCompany } from 'contexts/Company'
import { PickupButton } from './TicketRow/PickupButton/PickupButton'
import { useDialogStates } from './hooks/useDialogStates'
import { ObservationButton } from './TicketRow/ObservationButton/ObservationButton'
import { ActionsCell } from './TicketRow/ActionsCell/ActionsCell'
import { OfflineTicketEdition } from 'containers/OfflineExperiencesTicketForm/Edition/OfflineTicketEdition'
import { fiscalDutyDialog } from 'domains/Reservation/Show/Tickets/TicketSummary'
import { OFFLINE_EXPERIENCE_TYPE } from 'constants/activityTypes'
import { Integrations } from 'constants/integrations'
import { voucherStatusMap } from 'domains/CompanyReports/Vouchers/constants/voucherStatus'

const TicketDeadlineDate = ({
  deadlineDate = '',
  isEnabledTooltip = true,
  isDeadlineVisible = false,
  children
}) => {
  const formattedDate = useMemo(() => formatDate(deadlineDate, 'DD/MM/YYYY'), [deadlineDate])

  if (!isDeadlineVisible && isEnabledTooltip) return

  if (isEnabledTooltip) {
    return <div className={'wrapper'}>
      <InfoSwatch
        tooltip={`Data de deadline: ${formattedDate}`}
        customTooltip={[customDeadlineTooltip]}
      />
    </div>
  }
  return <div css={deadLineSummaryStyle} className={isDeadlineVisible && 'full-content'}>
    <p>
      {children}
    </p>
    {isDeadlineVisible && (
      <p css={marginTop(10)}>
        Data de deadline: {formattedDate}
      </p>
    )}
  </div>
}

export const TicketRow = ({
  ticket = {},
  pickupTickets = [],
  serviceId = '',
  reservationId = '',
  isSelected = false,
  hasEditableTickets = false,
  isCommercialPartner = false,
  linkPassengerLoading = false,
  pickupLocalLoading = false,
  observationLoading = false,
  isTransport = false,
  isOfflineExperience = false,
  onTicketDelete: emitDeleteEvent = identity,
  onTicketPrint: emitTicketPrint = identity,
  onTicketPrintVoucherTuris: emitTicketPrintVoucherTuris = identity,
  onTicketConfirm: emitTicketConfirm = identity,
  onLinkPassenger: emitLinkPassenger = identity,
  onUnlinkPassenger: emitUnlinkPassenger = identity,
  onEditPassenger: emitEditPassenger = identity,
  onLinkPickup: emitLinkPickup = identity,
  onUnlinkPickup: emitUnlinkPickup = identity,
  onEditClickPickup = identity,
  onTicketSelect = identity,
  createVoucherUnico = identity,
  createVoucherTuris = identity,
  cancelVoucherUnico = identity,
  cancelVoucherTuris = identity,
  setIsAllSelect = identity,
  onOpenObservationModal = identity,
  clearObservation = identity,
  onTicketUpdate = identity,
  hasFiscalDuty = false,
  isDeadlineVisible = false,
  isDisabled = false
}) => {
  const { id: useLoggedCompanyId } = useCompany()
  const isOwner = useMemo(() => useLoggedCompanyId === ticket?.companyId, [ticket?.companyId, useLoggedCompanyId])
  const {
    isCancelable = false,
    isEditable = false,
    isCancelableOnlyAsProvider = false
  } = ticketStatusDictionary[ticket?.state]
  const onSelectClick = () => onTicketSelect(ticket?.id, ticket?.externalIntegrations)
  const { add: addToast } = useToast()
  const vinculateRef = useRef()
  const generateRef = useRef()
  const [dialogType, setDialogType] = useState('')
  const [insertCodeDialogType, setInsertCodeDialogType] = useState('')
  const { setExternalId } = useExternalIdIntegration()

  const pickupTicketAttribute = (attribute, ticketId) => {
    if (pickupTickets.length > 0) {
      const pickupTicketsId = pickupTickets.map((pickup) => ({
        id: pickup.ticket.id,
        localName: pickup.pickUpDropOffAddress.name,
        pickupId: pickup.id
      }))

      const ticketLocalName = pickupTicketsId.filter((ticket) => ticketId === ticket.id)

      if (tail(ticketLocalName)?.[attribute] === undefined) return ''

      return tail(ticketLocalName)[attribute]
    }
  }

  const getPickupTicket = (ticketId) => {
    if (pickupTickets.length > 0) {
      const pickupTicket = pickupTickets.filter((pickup) => ticketId === pickup.ticket.id)

      return pickupTicket
    }
  }

  const pickupId = pickupTicketAttribute('pickupId', ticket?.id)

  const getVoucherUnicoIntegration = (externalIntegrations) => {
    return externalIntegrations.filter(
      (externalIntegration) => externalIntegration?.integrationName === Integrations.VoucherUnico
    )
  }

  const getVoucherTurisIntegration = (externalIntegrations) => {
    return externalIntegrations.filter(
      (externalIntegration) => externalIntegration?.integrationName === Integrations.PortalTuris
    )
  }

  const getVoucherUnicoIntegrationStatus = (externalIntegrations) => {
    return externalIntegrations.reduce((_, externalIntegration) => {
      if (externalIntegration?.integrationName === Integrations.VoucherUnico) return externalIntegration?.externalStatus
      return ''
    }, {})
  }

  const getVoucherTurisIntegrationStatus = (externalIntegrations) => {
    return externalIntegrations.reduce((_, externalIntegration) => {
      if (externalIntegration?.integrationName === Integrations.PortalTuris) return externalIntegration?.externalStatus
      return ''
    }, {})
  }

  const getVoucherUnicoIntegrationResponse = (externalIntegrations) => {
    return externalIntegrations.some(
      (externalIntegration) => externalIntegration?.response === 'Código gerado e associado manualmente'
    )
  }

  const getLimberIntegrationMessage = (externalIntegrations) => {
    const limberExternalIntegration = externalIntegrations.filter(
      (externalIntegration) => externalIntegration?.integrationName === Integrations.Limber
    )
    if (!limberExternalIntegration?.length) {
      return null
    }
    return {
      id: limberExternalIntegration
        .find(({ externalTicketId } = {}) => externalTicketId || false)?.externalTicketId,
      description: limberExternalIntegration.map(
        (externalIntegration) => {
          const {
            integrationName: name,
            externalStatus: status,
            extraData: { nome_local_embarque: embarque = '' } = {}
          } = externalIntegration
          return `${name}:\nStatus: ${voucherStatusMap[status]?.[0] || status}\nEmbarque: ${embarque || 'n/a'}`
        }
      )
        .join('\n---\n')
    }
  }

  const isIntegrationVoucherUnico = getVoucherUnicoIntegration(ticket?.externalIntegrations).length >= 1
  const isIntegrationVoucherTuris = getVoucherTurisIntegration(ticket?.externalIntegrations).length >= 1
  const isVoucherUnicoGenerated = getVoucherUnicoIntegrationStatus(ticket?.externalIntegrations) === 'generated'
  const isVoucherTurisGenerated = getVoucherTurisIntegrationStatus(ticket?.externalIntegrations) === 'generated'
  const isTicketConfirmed = useMemo(() => ticket?.state === 'confirmed', [ticket?.state])
  const isTicketUsed = useMemo(() => ticket?.state === 'used', [ticket?.state])
  const isTicketNoShow = useMemo(() => ticket?.state === 'no-show', [ticket?.state])
  const externalIntegrationId = head(getVoucherUnicoIntegration(ticket?.externalIntegrations))?.id
  const externalIntegrationTicketId = head(getVoucherUnicoIntegration(ticket?.externalIntegrations))?.externalTicketId
  const externalIntegrationName = head(getVoucherUnicoIntegration(ticket?.externalIntegrations))?.integrationName
  const isVoucherInserted = getVoucherUnicoIntegrationResponse(ticket?.externalIntegrations)
  const externalCode = head(ticket?.externalIntegrations)?.externalTicketId
  const limberIntegrationResponse = getLimberIntegrationMessage(ticket?.externalIntegrations)
  const externalIntegrationResponse = head(ticket?.externalIntegrations)?.response || ''

  const canCreateVoucher =
    ticket?.hasIntegration &&
    isIntegrationVoucherUnico &&
    isTicketConfirmed &&
    isCommercialPartner &&
    !isVoucherUnicoGenerated
  const canCreateVoucherTuris =
    ticket?.hasIntegration &&
    isIntegrationVoucherTuris &&
    isTicketConfirmed &&
    isCommercialPartner &&
    !isVoucherTurisGenerated
  const canInsertVoucher =
    ticket?.hasIntegration &&
    isIntegrationVoucherUnico &&
    isCommercialPartner &&
    (isTicketConfirmed || isTicketUsed || isTicketNoShow) &&
    !isVoucherUnicoGenerated

  const canConfirmTicket = ticket?.price <= 0 && ticket?.state === 'reserved'
  const confirmData = { reservationId, commercialPartnerId: ticket?.commercialPartnerId }

  const [isDialogVisible, setDialogVisible] = useState(false)
  const [isFiscalDutyBlockedDialogVisible, setFiscalDutyBlockedDialogVisible] = useState(false)
  const closeFiscalDutyBlockedDialog = () => setFiscalDutyBlockedDialogVisible(false)
  const [isInsertVoucherDialogVisible, setIsInsertVoucherDialogVisible] = useState(false)
  const toggleDialogVisible = (type) => {
    if (hasFiscalDuty && type?.toLowerCase()?.includes('delete')) {
      return setFiscalDutyBlockedDialogVisible(true)
    }
    setDialogType(type)
    setDialogVisible(true)
  }

  const [isTicketEditionDialogVisible, setIsTicketEditionDialogVisible] = useState(false)
  const toggleOfflineTicketEdition = useCallback(() => {
    setIsTicketEditionDialogVisible((v) => !v)
  }, [setIsTicketEditionDialogVisible])

  const toggleDialogHidden = () => setDialogVisible(false)

  const toggleInsertVoucherDialogVisible = (type) => {
    setInsertCodeDialogType(type)
    setIsInsertVoucherDialogVisible(true)
  }

  const toggleInsertVoucherDialogHidden = () => setIsInsertVoucherDialogVisible(false)

  const handleCreateVoucherUnico = (ticketId) => {
    createVoucherUnico([ticketId])
      .then(() => {
        addToast('Aguarde enquanto estamos criando o voucher', 'warning')
      })
      .catch(() => {
        addToast('Ocorreu um erro na criação do Voucher')
      })
  }

  const handleCreateVoucherTuris = (ticketId) => {
    createVoucherTuris([ticketId])
      .then(() => {
        addToast('Aguarde enquanto estamos criando o voucher', 'warning')
      })
      .catch(() => {
        addToast('Ocorreu um erro na criação do Voucher')
      })
  }

  const handleTicketPrint = (ticketId) => {
    emitTicketPrint(ticketId)
      .then((data) => {
        const { url: printUrl = '' } = data
        window.open(printUrl, '_blank', 'noopener noreferrer')
      })
      .catch(() => {})
  }

  const handleTicketPrintVoucherTuris = (ticketId) => {
    emitTicketPrintVoucherTuris(ticketId)
      .then((data) => {
        exportPdf(`voucher-${ticketId}`, data)
      })
      .catch(() => {})
  }

  const handleCancelVoucherUnico = (ticketId) => {
    cancelVoucherUnico([ticketId])
      .then(() => {
        addToast('Aguarde enquanto estamos cancelando o voucher', 'warning')
        toggleDialogHidden()
      })
      .catch(() => {
        addToast('Ocorreu um erro no cancelamento do Voucher')
      })
  }

  const handleCancelVoucherTuris = (ticketId) => {
    cancelVoucherTuris([ticketId])
      .then(() => {
        addToast('Aguarde enquanto estamos cancelando o voucher', 'warning')
        toggleDialogHidden()
      })
      .catch(() => {
        addToast('Ocorreu um erro no cancelamento do Voucher')
      })
  }

  const handleTicketDelete = (ticketId) => {
    emitDeleteEvent(ticketId)
    setIsAllSelect(false)
    toggleDialogHidden()
  }

  const handleTicketConfirm = (ticketId) => {
    emitTicketConfirm(ticketId, confirmData)
    setIsAllSelect(false)
    toggleDialogHidden()
  }

  const voucher = { voucherCode: '' }

  const dialogStates = useDialogStates(
    toggleDialogHidden,
    handleCreateVoucherUnico,
    handleCreateVoucherTuris,
    handleTicketDelete,
    handleCancelVoucherUnico,
    handleCancelVoucherTuris,
    handleTicketConfirm,
    ticket?.id
  )

  return (
    <>
      <Dialog dialogData={dialogStates[dialogType]} isVisible={isDialogVisible} />
      <DialogComponent
        customContainercss={[fiscalDutyDialog]}
        isVisible={isFiscalDutyBlockedDialogVisible}
        onClose={closeFiscalDutyBlockedDialog}
        title="Reserva com NF emitida!"
      >
        <p>Não é possível cancelar ingressos pois a reserva possui nota fiscal emitida.</p>
        <GhostPrimaryButton onClick={closeFiscalDutyBlockedDialog}>Entendido</GhostPrimaryButton>
      </DialogComponent>
      <DialogComponent
        customContainercss={OfflineTicketEditionDialogContainer}
        title={ticket?.ticketCode}
        onClose={toggleOfflineTicketEdition}
        isVisible={isTicketEditionDialogVisible}
      >
        <OfflineTicketEdition
          ticketId={ticket?.id}
          toggleOfflineTicketEdition={toggleOfflineTicketEdition}
          onTicketUpdate={onTicketUpdate}
        />
      </DialogComponent>

      <InsertCodeDialog
        isVisible={isInsertVoucherDialogVisible}
        onClose={toggleInsertVoucherDialogHidden}
        voucher={voucher}
        setExternalId={setExternalId}
        id={externalIntegrationId}
        externalIntegrationTicketId={externalIntegrationTicketId}
        integrationName={externalIntegrationName}
        insertCodeDialogType={insertCodeDialogType}
      />

      <TableBodyRow>
        {hasEditableTickets && (
          <TableBodyCell css={select} data-label="Selecionar">
            {isEditable && <Checkbox disabled={isDisabled} checked={isSelected} onChange={null} onClick={onSelectClick} />}
          </TableBodyCell>
        )}

        <TableBodyCell css={cellFontSize} data-label="Cód. Ingresso">
          {ticket?.ticketCode}
        </TableBodyCell>
        <TableBodyCell css={[cursorAuto, externalCodeCellContainer]} data-label="Cód. Externo">
          <div css={flexHorizontallyCentered}>
            {isVoucherUnicoGenerated || isVoucherTurisGenerated
              ? (
                <GeneratedVoucher
                  handleTicketPrint={isIntegrationVoucherUnico ? handleTicketPrint : handleTicketPrintVoucherTuris}
                  toggleDialogVisible={toggleDialogVisible}
                  externalCode={externalCode}
                  status={ticket?.state}
                  id={ticket?.id}
                  dialogAction={isIntegrationVoucherUnico ? CANCEL_TICKET : CANCEL_TICKET_TURIS}
                  externalIntegrationResponse={externalIntegrationResponse}
                  toggleEditVoucher={() => toggleInsertVoucherDialogVisible('edit')}
                />
              )
              : canCreateVoucher || canCreateVoucherTuris || canInsertVoucher || isVoucherInserted
                ? (
                  <div css={externalCodeBtnsContainer}>
                    <VoucherInsert
                      toggleInsertVoucherDialogVisible={toggleInsertVoucherDialogVisible}
                      id={ticket?.id}
                      reference={vinculateRef}
                      isVoucherInserted={isVoucherInserted}
                      externalCode={externalCode}
                      status={ticket?.state}
                    />
                    {!isVoucherInserted
                      ? (
                        <div css={voucherContainerStyle} ref={generateRef}>
                          <ProcessingContainer status={ticket?.state}>
                            <Button
                              onClick={() =>
                                toggleDialogVisible(isIntegrationVoucherUnico ? CREATE_VOUCHER : CREATE_VOUCHER_TURIS)
                              }
                            >
                        Gerar Voucher
                            </Button>
                          </ProcessingContainer>
                        </div>
                      )
                      : null}
                  </div>
                )
                : null}
            {limberIntegrationResponse
              ? (
                <>
                  {limberIntegrationResponse.id}
                  <InfoSwatch
                    css={marginLeft5}
                    size={200}
                    tooltip={limberIntegrationResponse.description.split('\n').map((line, idx) => <p key={idx}>{line}</p>)}
                  />
                </>
              )
              : externalIntegrationResponse
                ? (
                  <InfoSwatch css={marginLeft5} size={200} tooltip={externalIntegrationResponse} />
                )
                : null}
          </div>
        </TableBodyCell>
        {ticket?.title
          ? (
            <TruncateCell
              data-label="Atividade, Data e Hora"
              size="150px"
              css={[nameDateTimeCell]}
              tooltipStyle={tooltipWithDeadlineStyle}
              toolipText={<TicketDeadlineDate
                deadlineDate={ticket?.deadlineDate}
                isEnabledTooltip={false}
                isDeadlineVisible={isDeadlineVisible}
              >
                Atividade: {ticket?.title}
              </TicketDeadlineDate>}
            >
              <span>
                {ticket.ticketDates.startDate} {ticket.ticketDates.startHour}
              </span>
              <br />
              <span>
                {ticket.ticketDates.endDate} {ticket.ticketDates.endHour}
              </span>
            </TruncateCell>
          )
          : (
            <TableBodyCell data-label="Data e Hora" css={dateTimeCell}>
              <article>
                <div className='start-end-wrapper'>
                  <p>
                    {ticket.ticketDates.startDate} {ticket.ticketDates.startHour}
                  </p>
                  <p>
                    {ticket.ticketDates.endDate} {ticket.ticketDates.endHour}
                  </p>
                </div>
                <TicketDeadlineDate
                  deadlineDate={ticket?.deadlineDate}
                  isDeadlineVisible={isDeadlineVisible}
                />
              </article>
            </TableBodyCell>
          )}
        <TableBodyCell css={capitalize} data-label="Viajante">
          <LinkPassengerButton
            ticketId={ticket?.id}
            type={ticket?.type}
            passengerName={ticket?.passenger?.passengerName}
            linkPassengerLoading={linkPassengerLoading}
            onLinkPassenger={emitLinkPassenger(ticket?.id)}
            onUnlinkPassenger={() => emitUnlinkPassenger(ticket?.id)}
            onEditPassenger={() => emitEditPassenger(ticket?.passenger?.id)}
            isTransport={isTransport}
          />
        </TableBodyCell>
        {isTransport && (
          <TableBodyCell data-label="Local de embarque">
            <PickupButton
              ticketId={ticket?.id}
              serviceId={serviceId}
              localName={pickupTicketAttribute('localName', ticket?.id)}
              onLinkPickup={() => emitLinkPickup([ticket?.id], serviceId)}
              onUnlinkPickup={() => emitUnlinkPickup(pickupId, ticket?.id)}
              pickupLocalLoading={pickupLocalLoading}
              onEditClickPickup={onEditClickPickup}
              pickup={getPickupTicket(ticket?.id)}
              pickupId={pickupId}
              isTransport={isTransport}
            />
          </TableBodyCell>
        )}
        <TableBodyCell
          data-label="Status"
          onClick={canConfirmTicket ? () => toggleDialogVisible(CONFIRM_RESERVATION) : undefined}
        >
          {canConfirmTicket ? ConfirmTicket() : TicketStatus(ticket?.state)}
        </TableBodyCell>
        <TableBodyCell css={capitalize} data-label="Observação">
          <ObservationButton
            ticketId={ticket?.id}
            onOpenObservationModal={onOpenObservationModal(ticket?.id)}
            clearObservation={() => clearObservation(ticket?.id)}
            observation={ticket?.observation}
            observationLoading={observationLoading}
          />
        </TableBodyCell>
        <TableBodyCell data-label="Ação">
          <ActionsCell
            isCancelable={isCancelable}
            isCancelableOnlyAsProvider={isCancelableOnlyAsProvider}
            isOwner={isOwner}
            isOfflineExperience={isOfflineExperience || ticket?.serviceType === OFFLINE_EXPERIENCE_TYPE}
            toggleOfflineTicketEdition={toggleOfflineTicketEdition}
            toggleDialogVisible={toggleDialogVisible}
            hasFiscalDuty={hasFiscalDuty}
            ticketState={ticket?.state}
          />
        </TableBodyCell>
      </TableBodyRow>
    </>
  )
}
